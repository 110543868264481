import styled from 'styled-components';

// If we need to place something abs-positioned behind the FormPanel.
// (it's not possible to place abs-positioned element behind the parent FormPanel, negative z-index doesn't help)
export const FormPanelWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FormPanel = styled.div<{$width?: number, $withWrapper?: boolean, $hasBackground?: boolean}>`
  z-index: 1;          // "forgot password" hint should overlay left rectangle bubble
  position: ${({ $withWrapper }) => $withWrapper ? 'relative' : 'absolute'};
  top: ${({ $withWrapper }) => $withWrapper ? 'auto' : '50%'};
  left: ${({ $withWrapper }) => $withWrapper ? 'auto' : '50%'};
  transform: ${({ $withWrapper }) => $withWrapper ? 'none' : 'translate(30%, -50%)'};
  width: ${({ $width }) => `${$width ?? 400}px`};
  max-height: 100%;
  border-radius: 30px;
  background-color: ${({$hasBackground, theme}) => $hasBackground ? theme.colors.formBackground : 'transparent'};
  padding: 40px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  color: white;
  text-align: center;
`;

export const TextInput = styled.input`
  width: 100%;
  font-size: 1em;
  border-radius: 5px;
  padding: 0.5em 1em;
  line-height: 1;
`;
