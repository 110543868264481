import React from 'react';
import styled from 'styled-components';
import bgImage from '../img/backgrounds/floating laptop white flipped.webp';

const Root = styled.div`
    flex: 0 0 auto;
    height: 100%;
    padding: 70px 50px;
    display: flex;
    gap: 20px;
    flex-direction: column;
`;
const Picture = styled.div`
    flex: 1 1 auto;
    background: url("${bgImage}") no-repeat center;
    background-size: contain;
`;
const TextBig = styled.div`
    flex: 0 0 auto;
    font-size: 34px;
    font-weight: bold;
    line-height: 1.2;
`;
const TextColored = styled.span`
    color: #284D67;
`;
const TextSmall = styled.div`
    flex: 0 0 auto;
    font-size: 18px;
    line-height: 1.4;
`;

const PictureWithText = () => (
  <Root>
    <Picture/>
    <TextBig>Work faster. <TextColored>Collaborate smarter.</TextColored><br/>Stay in control.</TextBig>
    <TextSmall>Help your business work smarter and grow faster.<br/>www.cwaysoftware.com</TextSmall>
  </Root>
);

export default PictureWithText;

