import styled from 'styled-components';
import { colors } from '@collabra/cway-frontend-common/constants';

export const Button = styled.button<{disabled?: boolean, $secondary?: boolean}>`
  border-radius: 4px;
  background-color: ${({ $secondary }) => $secondary ? '#000000' : 'rgba(236, 172, 104)'};
  padding: 5px 15px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`;

export const ButtonLink = styled.a<{disabled?: boolean, $secondary?: boolean}>`
    border-radius: 4px;
    background-color: ${({ $secondary }) => $secondary ? '#000000' : 'rgba(236, 172, 104)'};
    padding: 5px 30px;
    font-size: 14px;
    color: white;
    font-weight: 400;
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    
    text-decoration: none;
`;
