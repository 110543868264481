import Hint from './Hint';
import {useEffect,  useState} from 'react';

const NO_HINT_CLASS_NAME = 'no_mouse_hint';
const HINT_CLASS_NAME = 'mouse_hint';

export interface MouseHintPosition {
  x: number | null,
  y: number | null,
}

const useMouseHint = () => {
  const [mouseHintPosition, setMouseHintPosition] = useState<MouseHintPosition>({x: null, y: null});

  const handleClickByHint = () => window.location.href = 'https://www.cwaysoftware.com/free-trial';

  const listenForMouseMovement = (event: MouseEvent) => {
    if (event.target instanceof HTMLElement) {
      if (!event.target.closest(`.${NO_HINT_CLASS_NAME}`)) {
        setMouseHintPosition({x: event.clientX, y: event.clientY});
        document.body.style.cursor = 'pointer';
        document.addEventListener('click', handleClickByHint)
      } else {
        setMouseHintPosition({x: null, y: null});
        document.body.style.cursor = 'default';
        document.removeEventListener('click', handleClickByHint)
      }
    }
  };

  useEffect(() => {
    document.body.addEventListener('mousemove', listenForMouseMovement);
    return () => {
      document.body.removeEventListener('mousemove', listenForMouseMovement);
    };
  }, []);

  return mouseHintPosition;
};

export { NO_HINT_CLASS_NAME, HINT_CLASS_NAME, useMouseHint, Hint };
