import React from 'react';
import styled from 'styled-components';
import softwareAdviceBadge from '../../img/icons/software-advice-most_recommended-2024.png';

const Root = styled.div`
    align-self: stretch;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
`;
const Link = styled.a<{$isCapterra?: boolean}>`
    flex: 0 0 auto;
    width: ${({$isCapterra}) => $isCapterra ? '100px' : 'auto'};
    height: ${({$isCapterra}) => $isCapterra ? '100px' : 'auto'};
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ImgSoftwareAdvice = styled.img`
    height: 120px;
    width: 120px;
    object-fit: cover;
`;
const ImgCapterra = styled.img`
    height: 50px;
`;
const ImgGetApp = styled.img`
    height: 100px;
`;

const GartnerLinks = () => (
  <Root>
    <Link href="https://www.softwareadvice.com/cms/cway-profile/reviews/" target="_blank" rel="noopener noreferrer">
      <ImgSoftwareAdvice src={softwareAdviceBadge} alt=""/>
    </Link>

    <Link $isCapterra href="https://www.capterra.com/p/10009310/Cway/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge" target="_blank" rel="noopener noreferrer">
      <ImgCapterra src="https://assets.capterra.com/badge/124602db971c1edb9049d8fbf50edc99.svg?v=2288344&p=341059" alt=""/>
    </Link>

    <Link href="https://www.getapp.com/marketing-software/a/cway/reviews/" target="_blank" rel="noopener noreferrer">
      <ImgGetApp src="https://www.getapp.com/ext/reviews_widget/v1/dark/cway-application" alt=""/>
    </Link>

    {/*<Link href="https://www.softwareadvice.com/cms/cway-profile/reviews/" target="_blank" rel="noopener noreferrer">*/}
    {/*  <Img src="https://badges.softwareadvice.com/reviews/bb07c0b7-f086-4fed-ac61-f07736b83025" alt=""/>*/}
    {/*</Link>*/}
  </Root>
);

export default GartnerLinks;
