import React from 'react';
import styled from 'styled-components';

const CHECKBOX_SIZE = 16;

const Root = styled.label`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;
const Input = styled.input`
  display: none;
`;
const CustomInput = styled.div<{$checked: boolean}>`
    cursor: pointer;
    width: ${CHECKBOX_SIZE}px;
    height: ${CHECKBOX_SIZE}px;
    border-radius: 3px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before, &:after {
        content: "";
        position: absolute;
        height: ${CHECKBOX_SIZE * 0.2}px;
        background-color: ${({theme, $checked}) => $checked ? theme.colors.standardTextColor : 'transparent'};
        border-radius: ${CHECKBOX_SIZE * 0.1}px;
        transform-origin: ${CHECKBOX_SIZE * 0.1}px ${CHECKBOX_SIZE * 0.1}px;
        left: 30%;
        top: 60%;
    }
    &:before {
        width: ${CHECKBOX_SIZE * 0.45}px;
        transform: rotate(230deg);
    }
    &:after {
        width: ${CHECKBOX_SIZE * 0.7}px;
        transform: rotate(310deg);
    }
`;
const Label = styled.span`
  line-height: 1;
`;

export interface CheckBoxProps {
  label?: React.ReactNode,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  checked: boolean,
}

const Checkbox = ({label, checked, onChange, ...rest}: CheckBoxProps) => (
    <Root {...rest}>
        <Input checked={checked} onChange={onChange} type="checkbox"/>
        <CustomInput $checked={checked}/>
        {label && <Label>{label}</Label>}
    </Root>
);

export default Checkbox;

// // Library packages
// import React from 'react';
// import cn from 'classnames';
//
// // Child components
// import MuiCheckbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
//
// // Constants
// import { colors, fontSm } from '@collabra/cway-frontend-common/constants';
//
// // Icons
// import { SquareOIcon, CheckIcon, TimesIcon } from '@collabra/cway-frontend-common/icons';
//
// // Styling
// import { makeStyles } from '@material-ui/core/styles';
// const size = 18;
// const useStyles = makeStyles({
//   checkbox: {
//     width: size,
//     height: size,
//   },
//   stackedIconsContainer: {
//     width: size,
//     height: size,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'relative',
//   },
//   iconBorder: {
//     width: size,
//     height: size,
//     color: colors.text,
//   },
//   iconMark: {
//     width: size * 0.6,
//     height: size * 0.6,
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//   },
//   iconMarkRose: {
//     color: colors.rose,
//   },
//   iconMarkBlue: {
//     color: colors.info,
//   },
//   labelControl: {
//     margin: 0,                    // override MUI value
//   },
//   label: {
//     marginLeft: '1em',
//     cursor: 'pointer',
//     paddingLeft: '0',
//     ...fontSm,
//     color: colors.text,           // override MUI value
//   },
//   labelDisabled: {
//     cursor: 'default',
//   },
// }, { name: 'Checkbox' });
//
// // Possible type prop values
// const checkboxTypesList = ['check', 'cross'] as const;
//
// interface Props {
//   type?: typeof checkboxTypesList[number],
//   checked: boolean,
//   onClick: () => void,
//   label?: React.ReactNode,
//   disabled?: boolean,
// }
//
// const Checkbox = ({ type = 'check', checked, onClick, label = null, disabled = false }: Props) => {
//   // ---------- Material-UI classes --------------------
//
//   const classes = useStyles();
//
//   // --------------------------------------------------------------------------------
//
//   const checkedIcons = {
//     check: <CheckIcon className={cn(classes.iconMark, classes.iconMarkRose)} />,
//     cross: <TimesIcon className={cn(classes.iconMark, classes.iconMarkBlue)} />,
//   };
//   const blankIcon = <SquareOIcon className={classes.iconBorder} />;
//   const checkedIcon = (
//     <div className={classes.stackedIconsContainer}>
//       <SquareOIcon className={classes.iconBorder} />
//       {checkedIcons[type]}
//     </div>
//   );
//
//   const chbComponent = (checkedChb: boolean, onClickChb: () => void, typeChb: typeof checkboxTypesList[number], disabledChb: boolean) => (
//     <MuiCheckbox
//       checked={checkedChb}
//       onClick={onClickChb}
//       icon={blankIcon}
//       checkedIcon={checkedIcon}
//       classes={{ root: classes.checkbox }}
//       disabled={disabledChb}
//     />
//   );
//
//   if (label) {
//     return (
//       <FormControlLabel
//         control={chbComponent(checked, onClick, type, disabled)}
//         label={label}
//         classes={{ root: classes.labelControl, label: cn(classes.label, { [classes.labelDisabled]: disabled }) }}
//       />
//     );
//   }
//
//   return chbComponent(checked, onClick, type, disabled);
// };
//
// export default Checkbox;
