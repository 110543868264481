import React, {useEffect, useState} from 'react';
import { /* Navigate, */ Navigate, useLocation} from 'react-router-dom';
import api from '../Api';
import {FormPanel} from './Form/StyledComponents';
import Error from './Error';
import styled from 'styled-components';
import {ssoLoginSentVar} from "../graphql/ReactiveVars";
import {useReactiveVar} from "@apollo/client";
import LoadingIndicator from './LoadingIndicator';

const Header = styled.h1`
  margin-bottom: 30px;
  font-size: x-large;
`;

interface Props {
    writeJwtToCookies: (accessToken: string, refreshToken: string) => void,
    refetch: () => void,
}

const SSOLandingPage = ({writeJwtToCookies, refetch}: Props) => {
    const search = useLocation().search;
    const [loggedIn, setLoggedIn] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const ssoLoginSent = useReactiveVar(ssoLoginSentVar);

    const params = new URLSearchParams(search);
    const code = params.get('code') ?? '';
    const state = params.get('state');
    const login = (token: string, state: string | null) => {
        console.group('%cSSOLandingPage.login()', 'color: red');
        console.log('token: ', token);
        api.loginWithSSOToken(
            token,
            state,
            (d) => {
                console.log('Login failed ', d);
                setLoginFailed(true);
            },
            (data) => {
                // @ts-ignore
                writeJwtToCookies(data.access_token, data.refresh_token);
                refetch();
                setLoggedIn(true);
            },
        );
        console.groupEnd();
    };

    useEffect(() => {
        if (!ssoLoginSent) {
            login(code, state);
            ssoLoginSentVar(true);
        }

    }, []);

    useEffect(() => {
        if (loginFailed) { // @ts-ignore
            setTimeout(() => window.location.href = process.env.REACT_APP_CWAY_HOST, 5000)
        }
    }, [loginFailed])

    if (loggedIn) return <div>Logged in</div>;

    if (loginFailed) {
        return (
            <FormPanel $hasBackground>
                <Header>Login failed!</Header>
                <Error errorMessage="Login to single signon provider failed. Please try again or with other account"/>
            </FormPanel>
        );
    }

    return (
        <FormPanel $hasBackground>
            <Header>Logging in. Please wait...</Header>
            <LoadingIndicator color="white" size={50}/>
        </FormPanel>
    );
};

export default SSOLandingPage;
