import React from 'react';
import styled from 'styled-components';
import {MouseHintPosition} from './index';

const Box = styled.div`
    z-index: ${({theme}) => theme.mouseHint.zIndex};
    position: fixed;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Text = styled.div`
    color: black;
    text-align: center;
`;

interface Props {
  position: MouseHintPosition,
}

const Hint = ({position}: Props) => {
  if (!position.x || !position.y)
    return null;

  return (
    <Box style={{left: position.x ?? 'unset', top: position.y ?? 'unset'}}>
      <Text>
        Take<br/>a<br/>look
      </Text>
    </Box>
  );
}

export default Hint;
