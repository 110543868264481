import React, {useEffect, useRef, useState} from 'react';
import CookieConsent from 'react-cookie-consent';
import {useCookies} from 'react-cookie';
import {gql, useQuery, useReactiveVar} from '@apollo/client';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import LoadingIndicator from './components/LoadingIndicator';
import EULA from './components/EULA';
import SelectOrganisation from './components/SelectOrganisation';
import ServerDown from './components/ServerDown';
import api from './Api';
import LoginBox from './components/Login';
import Onboard from './components/Onboard';
import SetPassword from './components/SetPassword';
import UserDataInput from './components/UserDataInput';
import RedirectToUserDataInputIfNeeded from './components/RedirectToUserDataInputIfNeeded';
import RedirectToOrganisationIfNeeded from './components/RedirectToOrganisationIfNeeded';
import RedirectToEulaIfNeeded from './components/RedirectToEulaIfNeeded';
import RedirectToCwayAppIfLoggedIn from './components/RedirectToCwayAppIfLoggedIn';
import RedirectToLoginPageIfNotLoggedIn from './components/RedirectToLoginPageIfNotLoggedIn';
import PasswordRequested from './components/PasswordRequested';
import ChangeEmail from './components/ChangeEmail';
import SSOLandingPage from './components/SSOLandingPage';
import {OrganisationMembership} from '@collabra/cway-frontend-common/types/__generated__/graphql';
import Download from './components/Download';
import TermsAndConditions from './components/TermsAndConditions';
import styled from 'styled-components';
import {organisationVar, urlQueryVar} from "./graphql/ReactiveVars";
import HubSpotLandingPage from "./components/HubSpotLandingPage";
import {MINIMUM_LOGO_ANIMATION_DELAY} from './constants';

const Root = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #E1EBEC, #FFFFFF);
  overflow-y: auto;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
`;

const LOGIN_INFO = gql`
    query LoginInfo {
        loginInfo {
            id
            user {
                id
                avatar
                username
                firstName
                lastName
                email
                acceptedTerms
                name
            }
            organisationMemberships {
                organisationId
                name
                permissionGroupId
            }
            currentOrganisation {
                id
                name
                active
            }
            settings {
                desktopUrl
                locale
                startUrl
            }
        }
    }
`;

const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

const getExpiry = (token: string) => {
    if (token == null) return new Date();
    return new Date(parseJwt(token).exp * 1000)
}

const App = () => {
    console.group('App()');
    console.log('window.location.href: ', window.location.href);

    const organisation = useReactiveVar(organisationVar);
    const navigate = useNavigate();

    // ---------- Store auth data in cookies --------------------

    const [cookies, setCookie, removeCookie] = useCookies(['main_token', 'main_refresh_token', 'main_org_id']);

    const writeJwtToCookies = (accessToken: string, refreshToken: string, orgId?: string) => {
        setCookie('main_token', accessToken, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: getExpiry(accessToken)});
        setCookie('main_refresh_token', refreshToken, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: getExpiry(refreshToken)});
        if (orgId) {
            setCookie('main_org_id', orgId, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN});
        }
    };
    const clearAuthCookies = () => {
        removeCookie('main_token', {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN});
        removeCookie('main_refresh_token', {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN});
        removeCookie('main_org_id', {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN});
    };

    // // ---------- Clear Hubspot cookies to fix problem with script loading --------------------
    //
    // const hubspotCookieNames = ['__hssc', '__hssrc', '__hstc', 'hubspotutk', 'messagesUtk'];
    // const [, ,removeHubspotCookie] = useCookies(hubspotCookieNames);
    // const clearHubspotCookies = () => {
    //     hubspotCookieNames.forEach((cookieName) => removeHubspotCookie(cookieName, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN}));
    // };

    // ---------- Get login info --------------------

    const {loading: loadingLoginInfo, error: errorLoginInfo, data: dataLoginInfo, refetch} = useQuery(LOGIN_INFO, {
        // // Poll with 30 sec interval if access token exists in cookies
        // pollInterval: cookies.main_token ? 30000 : 0,
        notifyOnNetworkStatusChange: true,
    });
    const loginInfo = dataLoginInfo ? dataLoginInfo.loginInfo : null;
    if (loginInfo) {
        console.log('loginInfo?.organisationMemberships: ', loginInfo?.organisationMemberships);
        console.log('loginInfo?.organisationMemberships.length: ', loginInfo?.organisationMemberships?.length);
    }

    const queryError = errorLoginInfo;
    const queryLoading = loadingLoginInfo;

    // ---------- Get URL params --------------------

    // Read url params to control the start of the app
    const params = new URLSearchParams(window.location.search);
    const paramForgot = params.get('forgot');
    const paramUsername = params.get('username');
    const organisationId = params.get('organisation_id');
    params.delete('organisation_id');
    params.delete('username');
    params.delete('forgot');
    params.delete('code');
    params.delete('state');
    params.delete('session_state');
    const queryString = params.toString();
    console.log('queryString: ', `"${queryString}"`);
    // ------------------------------------------------------------------------------------------

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // ---------- Minimum animation while waiting for the server response --------------------
    // If request takes more time, animation is extended respectively

    const [logoAnimationMinimumDelay, setLogoAnimationMinimumDelay] = useState(false);
    const startLogoAnimation = () => {
        setLogoAnimationMinimumDelay(true);
        setTimeout(() => setLogoAnimationMinimumDelay(false), MINIMUM_LOGO_ANIMATION_DELAY);
    };

    const loadingWithMinimumDelay = logoAnimationMinimumDelay || loading;

    // Basic state of the application
    const [online, setOnline] = useState(true);

    const handleError = (message: string) => {
        setError(message);
        setLoading(false);
    };

    const [navigateToPasswordRequested, setNavigateToPasswordRequested] = useState(false);
    const forgotPassword = (username: string) => {
        setLoading(true);
        startLogoAnimation();
        api.generateNewPasswordRequest(
            username,
            handleError,
            () => {
                setError(null);
                setLoading(false);
                setNavigateToPasswordRequested(true);
            });
    };

    const prevLoadingWithMinimumDelay = useRef(false);
    useEffect(() => {
        if (prevLoadingWithMinimumDelay.current && !loadingWithMinimumDelay) {
            if (navigateToPasswordRequested) {
                setNavigateToPasswordRequested(false);
                navigate('/password_requested');
            }
        }
        prevLoadingWithMinimumDelay.current = loadingWithMinimumDelay;
    }, [loadingWithMinimumDelay]);

    const selectOrganisation = (org: OrganisationMembership) => {
        api.selectOrganisation(
            org,
            cookies.main_refresh_token,
            (data) => {
                console.log(`Selected organisation ${org.name}`);
                console.log('data: ', data);
                // @ts-ignore
                writeJwtToCookies(data.access_token, data.refresh_token, org.organisationId);
                refetch();
            });
    };

    // Check if server is online... could be its own component
    const checkIfServerIsOnline = () => {
        if (!queryLoading && !loading) {
            api.checkServerVersion(
                () => setOnline(true),
                () => setOnline(false),
            );
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            checkIfServerIsOnline();
        }, 5000);

        checkIfServerIsOnline();

        //Set organisation id in reactive var so it can be reused later for login
        if (organisationId != null && organisationId != organisation)
            organisationVar(organisationId);

        if (queryString != null && queryString != '')
            urlQueryVar(queryString);

        return () => clearInterval(timer);
    });

    console.log('Organisation: ', organisation);

    const [loginAttempts, setLoginAttempts] = useState(0);  // count failed login attempts
    const login = (username: string, password: string) => {
        setLoading(true);
        setError(null);
        api.loginToServer(
            username,
            password,
            organisationVar(),
            (d) => {
                setLoginAttempts((prevValue) => prevValue + 1);
                handleError('Wrong username or password');
                clearAuthCookies();               // clear cookies on failed login is needed to prevent cookie collisions
                console.log('Login failed ', d);
            },
            (data) => {
                setError(null);
                // @ts-ignore
                writeJwtToCookies(data.access_token, data.refresh_token);
                // clearHubspotCookies();
                refetch();
                setLoading(false);
            },
        );
    };

    console.groupEnd();

    if (queryLoading) {
        return <LoadingIndicator fullscreen/>;
    }

    return (
        <Root>
            {
                online ? (
                    <Content>
                        <Routes>
                            <Route path="/download/:shareId" element={<Download/>}/>
                            <Route
                                path="/ssoAuth"
                                element={(<>
                                    <RedirectToCwayAppIfLoggedIn
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToOrganisationIfNeeded
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <SSOLandingPage writeJwtToCookies={writeJwtToCookies} refetch={refetch}/>
                                </>)}
                            />
                            <Route
                                path="/login"
                                element={(<>
                                    <RedirectToCwayAppIfLoggedIn
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToOrganisationIfNeeded
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToEulaIfNeeded
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToUserDataInputIfNeeded
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    {!loginInfo && (
                                        <LoginBox
                                            forgot={!!paramForgot || (loginAttempts > 2)}
                                            error={error}
                                            loading={loadingWithMinimumDelay}
                                            username={paramUsername}
                                            onLogin={login}
                                            onForgotPassword={forgotPassword}
                                            highlightForgotPwdButton={loginAttempts > 0}
                                        />
                                    )}
                                </>)}
                            />
                            <Route
                                path="/userDataInput"
                                element={(<>
                                    <RedirectToCwayAppIfLoggedIn
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToLoginPageIfNotLoggedIn
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToOrganisationIfNeeded
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <UserDataInput loginInfo={loginInfo}/>
                                </>)}
                            />
                            <Route
                                path="/organisation"
                                element={(<>
                                    <RedirectToCwayAppIfLoggedIn
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToEulaIfNeeded
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToLoginPageIfNotLoggedIn
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToUserDataInputIfNeeded
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    {!loginInfo?.currentOrganisation?.id && (
                                        <SelectOrganisation
                                            loginInfo={loginInfo}
                                            onSelectOrganisation={selectOrganisation}
                                        />
                                    )}
                                </>)}
                            />
                            <Route
                                path="/eula"
                                element={(<>
                                    <RedirectToCwayAppIfLoggedIn
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToLoginPageIfNotLoggedIn
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToOrganisationIfNeeded
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <RedirectToUserDataInputIfNeeded
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    {!loginInfo?.user?.acceptedTerms && <EULA/>}
                                </>)}
                            />
                            <Route
                                path="/register"
                                element={(<>
                                    <RedirectToCwayAppIfLoggedIn
                                        queryError={queryError}
                                        queryLoading={queryLoading}
                                        loginInfo={loginInfo}
                                    />
                                    <Onboard error={error} loading={loading}/>
                                </>)}
                            />
                            <Route path="/terms" element={<TermsAndConditions/>}/>
                            <Route path="/setPassword/:requestId" element={<SetPassword/>}/>
                            <Route path="/change_email/:requestId" element={<ChangeEmail/>}/>
                            <Route
                                path="/password_requested"
                                element={(
                                    <PasswordRequested
                                        error={error}
                                        loading={loading}
                                        goToLogin={() => {
                                            window.location.href = '/signon/login';
                                        }}
                                    />
                                )}
                            />
                            <Route path="*" element={<Navigate to="/login"/>}/>
                            <Route
                                path="/hubSpotLogin"
                                element={<HubSpotLandingPage />} />
                        </Routes>
                    </Content>
                ) : <ServerDown/>
            }

            <CookieConsent>This site uses cookies to enhance the user experience.</CookieConsent>
        </Root>
    );
};

export default App;
